<template>
	<div id="moncompte">
		<mainNav />
		<div class="content">
			<h2>Mon compte</h2>
			<van-cell-group title="Vos informations">
				<van-field :value="$store.state.user.fields.email" type="text" label="Email" readonly />
				<van-field :value="$store.state.user.fields.secteur" type="text" label="Secteur" readonly />
				<van-field :value="$store.state.user.fields.role.toUpperCase()" type="text" label="Rôle" readonly />
				<van-field :value="$store.state.user.fields.prenom + ' ' + $store.state.user.fields.nom" type="text" label="Nom" readonly />
				<van-field :value="$store.state.user.fields.prenom + ' ' + $store.state.user.fields.telephone" type="text" label="Téléphone" readonly />
				<van-field :value="$store.state.user.fields.derniere_connexion" type="text" label="Connexion" readonly />
				<van-field :value="$store.state.user.fields.wild_card" type="text" label="WildCards" readonly />
			</van-cell-group>
			<br />
			<form @submit.prevent="changePassword()">
				<van-cell-group title="Modifiez votre mot de passe"> </van-cell-group>
				<van-field
					v-model="password1"
					type="password"
					placeholder="Saisissez votre nouveau mot de passe"
					label="Mot de passe"
					:rules="[{ required: true, message: 'Merci de renseigner ce champ' }]"
				/>
				<van-field
					v-model="password2"
					type="password"
					placeholder="Confirmez votre nouveau mot de passe"
					label="Confirmez"
					:rules="[{ required: true, message: 'Merci de renseigner ce champ' }]"
				/>
				<van-cell v-if="ready_to_change">
					<p>Parfait, les mots de passe sont identiques.</p>
					<button type="submit">{{ bouton }}</button>
				</van-cell>
				<p v-else class="indication">
					Les mot de passes doivent être identiques et avoir une longueur d'au moins 8 caractères.
				</p>
			</form>
		</div>
	</div>
</template>
<script>
import mainNav from "../components/mainNav";
import md5 from "js-md5";
export default {
	name: "Compte",
	components: { mainNav },
	data() {
		return {
			password1: "",
			password2: "",
			message: "",
			bouton: "Modifier mon mot de passe"
		};
	},
	mounted() {
		//console.log(md5("viessmann" + "Viessm@nnp@c2021"));
	},
	computed: {
		ready_to_change() {
			let state = false;
			if (this.password1.length >= 8 && this.password2.length >= 8) {
				if (this.password1 === this.password2) {
					state = true;
				}
			}
			return state;
		}
	},
	methods: {
		changePassword() {
			let password = md5("viessmann" + this.password1);
			this.$store.dispatch("updateUserPassword", password).then(() => {
				this.bouton = "Votre mot de passe a bien été modifié.";
			});
		}
	}
};
</script>

<style lang="scss">
#moncompte {
	display: flex;
	padding: 0;
	margin: 0;
	background: white;
	min-height: 100vh;
	@media screen and (max-width: $screen-phone) {
		padding-top: 50px;
		min-height: 100vh;
	}

	.content {
		flex: 1;
		padding: 30px;
		h2 {
			font-size: 30px;
			font-weight: 300;
			margin-bottom: 40px;
		}
	}
	.van-cell-group {
		padding: 16px 0;
		&__title {
			font-weight: normal;
			font-size: 16px;
			font-weight: 300;
			color: $black;
			padding: 16px 0;
		}
	}
	.van-cell {
		font-size: 13px;
		font-weight: 300;
		line-height: 16px;
		padding: 8px 0;
		border: none;
		&:after {
			content: none;
		}
		&__title {
			width: 8em;
		}
		&__value {
			font-weight: normal;
			font-size: 14px;
		}
	}
	p {
		font-size: 12px;
		padding-top: 10px;
	}
	.indication {
		padding: 8px 0;
		font-size: 12px;
		font-weight: 300;
		font-style: italic;
	}
	input[type="password"] {
		letter-spacing: 1px;
	}
	button[type="submit"] {
		background: $rouge;
		color: white;
		padding: 6px 10px;
		border-radius: 4px;
		border: none;
		outline: none;
		font-size: 13px;
		margin-top: 10px;
		cursor: pointer;
		transition: all 0.3s ease;
		&:hover {
			background: darken($rouge, 10%);
		}
	}
}
</style>
